import React from 'react'
import styled from 'styled-components'

import { Container } from '../global'
import AussieMadeSvg from '../../images/australian-made.svg'
import { Link } from 'gatsby'

const company = [
  ['/pricing', 'Pricing']
  // ['/purchase', 'Purchase'],
  // ['/roadmap', 'Roadmap']
]
const legal = [
  ['/terms', 'Terms'],
  ['/billing', 'Billing']
]
const address = ['Tower 3, Level 25', '300 Barangaroo Ave', 'Sydney NSW 2000']
const contactus = [
  ['mailto:info@mountain-pass.com.au', 'General'],
  ['mailto:info@mountain-pass.com.au', 'Sales'],
  ['mailto:support@mountain-pass.com.au', 'Support']
]

const Footer = () => (
  <FooterWrapper id="footer">
    <FooterColumnContainer>
      {/* <FooterColumn>
        <span>Features</span>
        <ul>
          <li>Automation</li>
          <li>Rewards</li>
        </ul>
      </FooterColumn> */}
      <FooterColumn>
        <span>Company</span>
        <ul>
          {company.map(([a, b]) => (
            <li key={a}>
              <Link to={a}>{b}</Link>
            </li>
          ))}
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Legal</span>
        <ul>
          {legal.map(([a, b]) => (
            <li key={a}>
              <Link to={a}>{b}</Link>
            </li>
          ))}
        </ul>
      </FooterColumn>

      <FooterColumn>
        <span>Contact Us</span>
        <ul>
          {contactus.map(([a, b]) => (
            <li key={b}>
              <a href={`mailto:${a}`}>{b}</a>
            </li>
          ))}
        </ul>
      </FooterColumn>

      <FooterColumn>
        <span>Address</span>
        <ul>
          {address.map((a) => (
            <li key={a}>{a}</li>
          ))}
        </ul>
      </FooterColumn>
    </FooterColumnContainer>
    <BrandContainer>
      <Logo>Cloud Hosting / Mac</Logo>
      <AussieMade src={AussieMadeSvg} />
    </BrandContainer>
  </FooterWrapper>
)

const AussieMade = styled.img`
  height: 100px;
  width: 100px;
`

const FooterWrapper = styled.footer`
  background-color: white;
  margin: 80px 0 0;
  padding: 0 0 80px;
  a {
    color: ${(props) => props.theme.color.primary};
  }
`

const Logo = styled.div`
  font-family: ${(props) => props.theme.font.extrabold};
  ${(props) => props.theme.font_size.regular};
  color: ${(props) => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
`

const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 48px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
  }
`
const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`
const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-family: ${(props) => props.theme.font.bold};
    color: ${(props) => props.theme.color.primary};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${(props) => props.theme.color.black.regular};
    li {
      margin-bottom: 12px;
      font-family: ${(props) => props.theme.font.normal};
      font-size: 15px;
    }
  }
`

export default Footer

import React from 'react'
import styled from 'styled-components'

const contactForm = () => {
  const [email, setEmail] = React.useState('')
  const [thankYou, setThankYou] = React.useState(null)

  const handleSubmit = (event) => {
    event.preventDefault()
    fetch('/api/notify', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    })
      .then((resp) => {
        if (resp.status === 200) {
          setThankYou('Thank you, we will contact you shortly.')
        } else {
          setThankYou(
            'An error occurred. Please try the contact method, below in the footer.'
          )
        }
      })
      .catch((err) => {
        setThankYou(
          'An error occurred. Please try the contact method, below in the footer.'
        )
        // eslint-disable-next-line
        console.error(err.message, err)
      })
  }

  return (
    <>
      <HeaderForm onSubmit={handleSubmit}>
        <HeaderInput
          placeholder="Your email or mobile number"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <HeaderButton>Get Early Access</HeaderButton>
      </HeaderForm>
      <HeaderThankYou style={{ display: thankYou !== null ? 'block' : 'none' }}>
        {thankYou}
      </HeaderThankYou>
      {/* <FormSubtitle>
            Already have a beta account?{" "}
            <FormSubtitleLink to="/">Sign in</FormSubtitleLink>
          </FormSubtitle> */}
    </>
  )
}

export default contactForm

const HeaderThankYou = styled.div`
  font-size: 22px;
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  margin-top: 16px;
`

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  min-width: 300px;
  &:focus {
    box-shadow: inset ${(props) => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
